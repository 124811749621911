import React from "react";

const SEO = ({title,children,description,keywords,viewport}) => {
    return (
        <>
            <title>{title}</title>
            <meta name="title" content={`${title}`} />
            <meta name="og:title" content={`${title}`} />
            <meta name="og:description" content={`${description}`} />
            <meta name="description" content={`${description}`} /> 
            <meta name="keywords" content={keywords} />
            <meta name="viewport" content={viewport} />
            {children}
        </>
    );
};

export default SEO;
